<template>
    <b-overlay :show='showLoader'>
        <div v-if="item" class="test h-100">
            <StoreCategories class="mb-4"/>
            <b-row>
                <b-col order="1" cols="12" md='6'>
                    <div class="gallery pb-2">
                        <b-img v-if="item.main_picture !== ''" fluid :src="'/api/user/v1/web_shop/product_image/1000_' + item.main_picture"/>
                        <b-img v-else :src="require('@/assets/images/esoseska/no-image.jpg')"/>
                    </div>
                </b-col>
                <b-col order="3" order-md="2" class="mt-4 mt-md-0" cols="12" md='6'>
                    <div class="d-flex flex-column h-100 pb-2">
                        <div class="mb-auto">
                            <h3 style="font-weight: bold;" v-if="item.manufacturer_title !== ''">
                                Izdelek {{item.manufacturer_title}}
                            </h3>
                            <h4>{{item.description_1}}</h4>
                            <div style="font-size: 12px;" >Šifra: <div class="d-inline text-one-line" style="color: #72A5D8;">{{item.id}}</div></div>
                            <div class="mt-2">
                                <div class="cut-text">
                                    {{item.description_2}}
                                </div>
                                <div @click="jumpToDescription" style="cursor: pointer; color: #72a5d8; font-weight: bold;">
                                    preberi več
                                </div>
                            </div>
                        </div>

                        <div>
                            <b-tabs v-model="activeTab" content-class="mt-1" class="price-tabs" v-if="promotion && promotion.bbis_id == itemId">
                                <b-tab title="Brez točk"  active>
                                    <div>
                                        <div class="price-config">
                                            <div style="font-size: 1.2rem; margin-bottom: 10px;">
                                                Cena:
                                            </div>
                                            <div>
                                                <h1 class="m-0 font">{{$convertPriceIntegerToFloat(item.price)}} €</h1>
                                            </div>
                                        </div>
                                        <!-- <div class="price-config">
                                <div style="font-size: 1.2rem; margin-bottom: 10px; color: #009FD4;">
                                    Cena z točkami: <span style="font-size: 15px; color: black; font-weight: bold;">{{$convertPriceIntegerToFloat(item.price*0.9)}} €</span>
                                </div>
                            </div> -->
                                        <b-row v-if="item.price > installmentPaymentPrice" class="mt-2">
                                            <b-col cols="12" md="6">
                                                <h3 class="d-inline mr-1"><fa style="color: #009FD4;" icon="coins"/></h3>
                                                <div class="d-inline" style="font-weight: bold;">
                                                    MOŽNOST PLAČILA NA OBROKE
                                                </div>
                                            </b-col>
                                            <!-- <b-col cols="12" md="6">
                                    <h3 class="d-inline mr-1"><fa style="color: #009FD4;" icon="truck"/></h3>
                                    <div class="d-inline">
                                        BREZPLAČNA DOSTAVA
                                    </div>
                                </b-col> -->
                                        </b-row>
                                        <!-- <b-row class="mt-1">
                                <b-col cols="6" md="4" v-for="(item,id) in additionally" :key="'item_additional_'+id">
                                    <b-form-checkbox
                                        v-model="additionalValue[id]"
                                        value="accepted"
                                        unchecked-value="not_accepted"

                                    >
                                        {{item.title}}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row> -->
                                    </div>
                                </b-tab>
                                <b-tab title="Točke zvestobe" v-if="promotion">
                                    <div >
                                        <div class="price-config">
                                            <div style="font-size: 1.2rem; margin-bottom: 5px;">
                                                Redna cena:
                                            </div>
                                            <div style="margin-bottom: 5px;">
                                                <h5 class="m-0 font" style="text-decoration: line-through;" >{{$convertPriceIntegerToFloat(item.price)}} €</h5>
                                            </div>
                                            <div style="font-size: 1.2rem; margin-bottom: 10px;">
                                                Cena z uporabo <span style="color: #009FD4;">{{promotion.number_of_coupons}} {{coupons_text}}</span>:
                                            </div>
                                            <div>
                                                <h1 class="m-0 font">{{$convertPriceIntegerToFloat(promotion.price)}} €</h1>
                                            </div>
                                        </div>
                                        <b-row v-if="item.price > installmentPaymentPrice" class="mt-2">
                                            <b-col cols="12" md="6">
                                                <h3 class="d-inline mr-1"><fa style="color: #009FD4;" icon="coins"/></h3>
                                                <div class="d-inline" style="font-weight: bold;">
                                                    MOŽNOST PLAČILA NA OBROKE
                                                </div>
                                            </b-col>
                                        <!-- <b-col cols="12" md="6">
                                    <h3 class="d-inline mr-1"><fa style="color: #009FD4;" icon="truck"/></h3>
                                    <div class="d-inline">
                                        BREZPLAČNA DOSTAVA
                                    </div>
                                </b-col> -->
                                        </b-row>
                                    </div>
                                </b-tab>
                            </b-tabs>
                            <div v-else>
                                <div class="price-config">
                                    <div style="font-size: 1.2rem; margin-bottom: 10px;">
                                        Cena:
                                    </div>
                                    <div>
                                        <h1 class="m-0 font">{{$convertPriceIntegerToFloat(item.price)}} €</h1>
                                    </div>
                                </div>
                                <b-row v-if="item.price > installmentPaymentPrice" class="mt-2">
                                    <b-col cols="12" md="6">
                                        <h3 class="d-inline mr-1"><fa style="color: #009FD4;" icon="coins"/></h3>
                                        <div class="d-inline" style="font-weight: bold;">
                                            MOŽNOST PLAČILA NA OBROKE
                                        </div>
                                    </b-col>
                            
                                </b-row>
                            </div>
                        </div>  
                       
                    </div>
                </b-col>
                <b-col order="2" order-md="3" cols="12" md='6'>
                    <div v-if="item.additional_pictures && item.additional_pictures.length > 0">
                        <swiper :swiper-data="item.additional_pictures" :pagination="false" type="webShopGallery"/>
                    </div>
                </b-col>
                <b-col order="4" cols="12" md='6'>
                    <div>
                        <div class="text-center" style="margin-left: 109px; color: red; font-size: 10px;" v-if="promotion && (number_of_coupons < promotion.number_of_coupons) && activeTab == 1">Za nakup izdelka, žal nimate dovolj kuponov</div>
                        <div class="d-flex w-100 mt-1" v-if="item.in_stock > 0">
                            <div>
                                <b-form-spinbutton v-model="quantity" min="1" :max="item.in_stock" @change="setQuantity" inline/>
                            </div>
                            <b-button  class="font mx-1" block pill @click="onAddItemToShoppingCart" v-if="activeTab != 1">DODAJ V KOŠARICO</b-button>
                            <b-button :disabled="promotion && (number_of_coupons < promotion.number_of_coupons)"  class="font mx-1" block pill @click="onAddItemToShoppingCartCoupons" v-if="activeTab == 1">DODAJ V KOŠARICO IN UPORABI KUPONE</b-button>
                        </div>
                        <div class="mt-1" v-else>
                            <b-button class="font button-item" block pill @click="openModalForStockInquiry">Pošlji povpraševanje</b-button>
                        </div>
                    </div>
                </b-col>

            </b-row>
            <b-tabs id="description" class="desc-tabs mt-2" content-class="mt-3">
                <b-tab title="Predstavitev" active>
                    <!-- <h3>{{item.description_1}}</h3> -->
                    <!-- <h5>{{item.description_2}}</h5> -->
                    <p v-html="item.description_long"></p>
                </b-tab>
                <b-tab title="Lastnosti">
                    <table class="table w-100">
                        <tr v-if="item.manufacturer_title !== ''">
                            <th>ZNAMKA</th>
                            <td>{{item.manufacturer_title}}</td>
                        </tr>
                        <tr v-if="item.measuring_unit !== ''">
                            <th>MERSKA ENOTA</th>
                            <td>{{item.measuring_unit}}</td>
                        </tr>
                        <tr v-if="item.color !== ''">
                            <th>BARVA</th>
                            <td>{{item.color}}</td>
                        </tr>
                        <tr v-if="item.size_table !== ''">
                            <th>VELIKOST</th>
                            <td>{{item.size_table}}</td>
                        </tr>
                        <tr v-if="item.style !== ''">
                            <th>STIL</th>
                            <td>{{item.style}}</td>
                        </tr>
                        <tr v-if="item.weight !== '0'">
                            <th>TEŽA</th>
                            <td>{{item.weight}}</td>
                        </tr>
                        <tr v-if="item.width !== '0'">
                            <th>ŠIRINA</th>
                            <td>{{item.width}}</td>
                        </tr>
                    </table>
                </b-tab>
            </b-tabs>
            <div class="text-center mt-5">
                <h1 class="font">Sorodni izdelki</h1>
                <swiper :swiper-data="similarProducts" :pagination="true" type="sale"/>
            </div>
        </div>
        <modal-send-stock-inquiry ref="ModelHolder" :item="item"/>
    </b-overlay>
</template>

<script>
    import Swiper from '../../Components/Swiper'
    //import CheckboxField from '../../Components/CheckboxField'
    import {BRow, BCol,  BTabs, BTab, BFormSpinbutton, BButton, BOverlay, BImg } from 'bootstrap-vue'
    import ModalSendStockInquiry from './ModalSendStockInquiry.vue'
    import StoreCategories from '@/views/Components/StoreCategories'

    export default {
        components: {
            Swiper,
            //CheckboxField,
            BRow,
            BCol,
            BTabs,
            BTab,
            BFormSpinbutton,
            BButton,
            BOverlay,
            BImg,
            ModalSendStockInquiry,
            StoreCategories
        },
        data() {

            return {
                activeTab: null,
                showLoader: false,
                itemId: '',
                item: null,
                similarProducts: [],
                points: 0,
                price: 389.99,
                basicPrice: 389.99,
                additionalValue: [],
                additionally: [{title: 'PRIKLOP', price: 20.00, checked: false}, {title: 'ODVOZ', price: 20.00, checked: false}],
                quantity: 1,
                accessories: [
                    {icon: 'truck', text: 'BREZPLAČNA DOSTAVA'},
                    {icon: 'coins', text: 'MOŽNOST PLAČILA NA OBROKE'}
                ],
                installmentPaymentPrice: 0,
                categories: [],
                promotion: null,
                number_of_coupons: '',
                coupons_text: ''
            }
        },
        methods: {
            jumpToDescription() {
                const element = document.getElementById('description')
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
            },
            openModalForStockInquiry() {
                this.$refs.ModelHolder.$refs.ModalSendInquiry.show()
            },
            onAddItemToShoppingCartCoupons() {
                const data = {
                    item: this.item,
                    quantity: this.quantity,
                    promotional_price: true,
                    price_with_coupons: this.promotion.price
                }

                data.item.id += '_coupons'


                this.showLoader = true
                this.$store.dispatch('shoppingCart/addItemToCart', data).then(() => {
                    this.$router.push({name:'shopping-card'})
                }).finally(() => {
                    this.showLoader = false
                })
            },
            onAddItemToShoppingCart() {

                const data = {
                    item: this.item,
                    quantity: this.quantity
                }
                this.showLoader = true
                this.$store.dispatch('shoppingCart/addItemToCart', data).then(() => {
                    this.$router.push({name:'shopping-card'})
                }).finally(() => {
                    this.showLoader = false
                })
            },
            setPrice(item) {
                if (item.checked) {
                    this.price += item.price
                } else {
                    this.price -= item.price
                }
            },
            setQuantity() {
                this.price = (this.basicPrice * this.quantity).toFixed(2)
            },
            async loadData() {
                this.showLoader = true
                this.$scrollToTop()
                try {
                    await this.loadItem()
                    await this.loadPromotions()
                    await this.loadCoupons()
                    await this.loadOtherData()
                    await this.loadCategories()
                    await this.setBreadchrumbs()
                    await this.loadInstallmentPaymentPrice()
                    if (this.promotion && this.promotion.number_of_coupons > 0) this.activeTab = 1
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            async loadCoupons() {
                try {
                    const res_points_total = await this.$http.get('/api/user/v1/points/get_collected_points')
                    this.points = res_points_total.data
                    if (this.points) {
                        this.number_of_coupons = parseInt(this.points / 300)
                    }
                } catch (error) {
                    this.$printError('Pri nalaganju izdelkov je prišlo do napake!')
                }
            },
            async loadPromotions() {
                try {
                    const res = await this.$http.get(`/api/user/v1/promotions/products/${this.itemId}`)
                    this.promotion = res.data

                    if (this.promotion) {
                        if (this.promotion.number_of_coupons === 1) {
                            this.coupons_text = 'kupona'
                        } else if (this.promotion.number_of_coupons >= 2) {
                            this.coupons_text = 'kuponov'
                        }
                    }
                  
                } catch (error) {
                    this.$printError('Pri nalaganju izdelkov je prišlo do napake!')
                }
            },
            async loadItem() {
                const thisIns = this

                try {
                    const result = await thisIns.$http.get(`/api/user/v1/web_shop/?id=${this.itemId}`)
                    this.item = result.data.items[0] ?? null
                } catch (error) {
                    this.$printError('Pri nalaganju izdelkov je prišlo do napake!')
                }

            },
            async loadOtherData() {
                const thisIns = this

                try {
                    const resultSimilar = await thisIns.$http.get(`/api/user/v1/web_shop/?split_categories.[]=${this.item.categories[0].category}&perPage=8&current_product=${this.item.id}`)
                    this.similarProducts = resultSimilar.data.items ?? []

                } catch (error) {
                    this.$printError('Pri nalaganju izdelkov je prišlo do napake!')
                }
            },
            async loadInstallmentPaymentPrice() {
                const response = await this.$http.get('/api/user/v1/web_shop/cart/installment_payment')
                this.installmentPaymentPrice = response.data.price
            },
            async loadCategories() {
                const res = await this.$http.get('/api/user/v1/web_shop/category')
                this.categories = res.data
            },
            setBreadchrumbs() {
                let category = null

                category = this.categories.find(x => x.id === this.item.category.id)
                if (category !== undefined) {
                    this.$route.meta.breadcrumb = [{text: category.title, to: `/izdelki/${category.id}`, active: true}]
                }
                    
                if (category === undefined) {
                    const mainCategory = this.categories.find(x => x.children.find(y => y.id === this.item.category.id))
                    if (mainCategory && mainCategory !== undefined) {
                        category = mainCategory.children.find(x => x.id === this.item.category.id)
                        if (category !== undefined) {
                            this.$route.meta.breadcrumb = [
                                {text: mainCategory.title, to: `/izdelki/${mainCategory.id}`, active: false},
                                {text: category.title, to: `/izdelki/${category.id}`, active: true}
                            ]
                        }
                    } else {
                        const mainCategory = this.categories.find(x => x.children.find(y => y.children.find(z => z.id === this.item.category.id)))
                        if (mainCategory) {
                            const subCategory1 = mainCategory.children.find(x => x.children.find(y => y.id === this.item.category.id))
                            if (subCategory1) {
                                category = subCategory1.children.find(x => x.id === this.item.category.id)
                                if (category !== undefined) {
                                    this.$route.meta.breadcrumb = [
                                        {text: mainCategory.title, to: `/izdelki/${mainCategory.id}`, active: false},
                                        {text: subCategory1.title, to: `/izdelki/${subCategory1.id}`, active: false},
                                        {text: category.title, to: `/izdelki/${category.id}`, active: false},
                                        {text: this.item.description_1, to: `/izdelki/${this.item.id}`, active: true}
                                    ]
                                }
                            }
                        }
                    }
                }
                this.$root.$emit('force-update-breadcrumbs')

                /*if (this.item && this.item.category.title !== '') {

                    this.$route.meta.breadcrumb[1].text = this.item.category.title
                    this.$route.meta.breadcrumb[1].to = `/izdelki/${this.item.category.id}`
                    this.$route.meta.breadcrumb[2].text = this.item.description_1
                    this.$root.$emit('force-update-breadcrumbs')
                }*/
            }
        },
        async mounted() {
            if (this.$route.params.item_id) this.itemId = this.$route.params.item_id
            await this.loadData()
        },
        watch: {
            async '$route.params.item_id'(item_id) {
                this.itemId = item_id
                await this.loadData()
            }
        }
    }
</script>

<style scoped>
.cut-text{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-one-line{
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
overflow: hidden;
}

.gallery {
  max-height: 500px !important;
  display: flex;
  justify-content: center;
}
.gallery .img-fluid {
  max-height: 500px !important;
}

.table tr:first-child td, .table tr:first-child th {
  border-top: none !important;
}
</style>

<style>
price-tab

.desc-tabs ul li a {
  font-size: 15px;
}
.desc-tabs .nav-link {
    color: #CFCFCF !important;
}
.desc-tabs ul li .active {
    color: black !important;
    font-weight: bold;
}
.desc-tabs ul li a:hover {
    /* color: black !important; */
    color: #CFCFCF !important;
    font-weight: bold;
}
.price-tabs ul li .active{
  background-color: transparent !important;
  color: #72a5d8 !important;
}
.desc-tabs .nav-tabs  {
  border-top: 3px solid #E6E6E6;
  font-size: 18px;
  padding-top: 10px !important;
}
.desc-tabs .nav-link  {
  padding-right: 30px !important;
  padding-left: 0px !important;
}

.price-tabs .nav-tabs  {
  border-bottom: none;
  font-size: 18px;
}
.desc-tabs .nav-tabs .nav-link:after, .price-tabs .nav-tabs .nav-link:after {
  background: none !important;
}

.price-tabs ul li a {
  padding-left: 0px !important;
}

.price-tabs .nav-link {
  background-color: transparent !important;
  color: black !important;
}
.price-tabs .nav-link.active:hover{
  color: #72a5d8 !important;
  font-weight: bold;
}
.price-tabs .nav-link:hover{
  color: #72a5d8 !important;
  font-weight: bold;
}
.price-config {
  /* border: 2px solid #C4C4C4; */
  background: #F3F3F3;
  padding: 10px 10px 10px 10px;
}
.b-form-spinbutton {
  color: black !important;
  background: #E7E6E6;
  border: 2px solid #E7E6E6;
  border-radius: 0;
}
.b-form-spinbutton button {
  color: black;
}

.b-form-spinbutton button .bi-plus{
  max-width: 12px;
}

.b-form-spinbutton button .bi-dash{
  max-width: 12px;
}

p a {
  color: #009FD4 !important;
  font-weight: bold;
}
.b-form-spinbutton output {
  border: none !important;
}

#zoom {
  position: relative;
  top: -25px;
  left: 2px;
  color: white;
}
.font {
  font-weight: bold;
}
</style>

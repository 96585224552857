<template>
    
    <div v-if="item">
        <BModal ref="ModalSendInquiry" hide-footer>

            <template #modal-header="">
                <div class="w-100 d-flex justify-content-center" >
                    <h3 style="font-weight: bold;">Pošlji povpraševanje</h3>
                </div>
            </template>
            <template #default>
                <div>

                    <div>
                        <InputField label="Artikel" class="mb-1" v-model="item.description_1" :isDisabled="true" :profile="true"/>
                        <InputField label="Artikel ID" class="mb-1" v-model="item.id" :isDisabled="true" :profile="true"/>
                        <InputField label="Ime" class="mb-1" v-model="user.name" :profile="true"/>
                        <InputField label="Priimek" class="mb-1" v-model="user.surname" :profile="true"/>
                        <InputField label="Email" class="mb-1" v-model="user.email" :profile="true" />
                        <InputField label="Telefonska številka" class="mb-1" v-model="user.telephone_number" :profile="true" />
                        <InputField :textArea="true" label="Opis" class="mb-1" v-model="object.message" :profile="true"/>
                    </div>

                    <div class="mt-2 d-flex justify-content-center">
                        <BButton class="mx-1" @click="hideMe">Prekliči</BButton>
                        <BButton class="mx-1" :disabled="disabled" @click="sendInquiry">Pošlji</BButton>
                    </div>
                </div>
            </template>
            
        </BModal>
    </div>
</template>

<script>
    import {BModal, BButton} from 'bootstrap-vue'
    import InputField from '@/views/Components/InputField.vue'

    export default {
        components:{
            BModal,
            BButton,
            InputField
        },
        props:{
            item:{
                type: Object
            }
        },
        data() {
            return {
                user:{
                    name: '',
                    surname: '',
                    email: '',
                    telephone_number: ''
                },
                object: {
                    name: '',
                    email_from: '',
                    email_to: '',
                    telephone_number: '',
                    message: '',
                    subject: ''
                },
                disabled: false
            }
        },
        methods:{
            hideMe() {
                this.$refs.ModalSendInquiry.hide()
            },
            async sendInquiry() {
                this.disabled = true
                try {
                    this.object.name = `${this.user.name} ${this.user.surname}`
                    this.object.email_from = this.user.email
                    this.object.telephone_number = this.user.telephone_number
                    this.object.subject = `Povpraševanje za izdelek: ${this.item.id}, ${this.item.description_1}`

                    await this.$http.post('/api/user/v1/send_mail', this.object)
                    this.$printSuccess('Email je bil uspešno poslan')
                    this.object.subject = ''
                    this.$refs.ModalSendInquiry.hide()
                } catch (error) {
                    this.$printError(`Pri pošiljanju emaila je prišlo do napake!\n${error.message}`)
                } finally {
                    this.disabled = false
                }
            }
        },
        mounted() {
            if (this.$store.state.user.userData !== null) {
                this.user.name = this.$store.state.user.userData.name
                this.user.surname = this.$store.state.user.userData.surname
                this.user.email = this.$store.state.user.userData.email
                this.user.telephone_number = this.$store.state.user.userData.telephone_number
            }
        }
    }
</script>
